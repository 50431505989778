import React from 'react';
import useResponsiveLayout from '../../hooks/useResponsiveLayout';

function HeroBackgroundImage() {
  const { isMobile, isTablet } = useResponsiveLayout();

  const overlayClasses = `absolute inset-0 bg-white z-10 ${
    isMobile || isTablet
      ? 'bg-gradient-to-b from-white via-white to-transparent opacity-70'
      : 'opacity-50'
  }`;

  return (
    <div className="section-hero-background elem66 box-border text-white h-[100%] absolute overflow-hidden z-[-1] text-center bg-[color:#dadada] flex-col justify-between items-center flex mb-[2rem] pt-[2rem] pb-[4rem] px-[2rem] rounded-[0.75rem] inset-[0%] tablet:h-[100%] mobile:h-[100%]">
      <div className={overlayClasses}></div>
      <img
        src="https://createsiteai.b-cdn.net/e4b71724-5961-4e04-af7e-0cd4a62ffaa7"
        alt="Hero background"
        className="elem9 align-baseline inline-block box-border object-cover z-[-100] bg-[position:50%] bg-[size:cover] w-[100%] h-[100%] absolute m-auto -inset-full brightness-110"
      />
    </div>
  );
}

export default HeroBackgroundImage;