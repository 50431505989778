import React from 'react';
import FooterSubscribeForm from "./FooterSubscribeForm";

function FooterSubscribeColumn() {
  return (
    <div className="footer-col wide elem85 box-border min-w-[25%] flex-col flex-none justify-between w-[80%] flex items-center tablet:min-w-[50%] mobile:min-w-[50%] tablet:w-[100%] mobile:w-[100%]">
      <h3 className="footer-heading elem86 box-border font-normal text-[length:2.5rem] leading-[1.2] my-[0] text-center tablet:mb-[4rem] mobile:mb-[4rem] mobile:text-[length:2.5rem] mobile:leading-[100%]">
        Stay tuned, we are launching soon
      </h3>
      <div className="w-form elem87 box-border mt-[0] mb-[15px] mx-[0] w-full">
        <p className="paragraph is-medium elem88 box-border text-[length:1.25rem] my-[0] text-center mobile:text-[length:1.25rem]">
          Be the first to know when we launch. Sign up now to receive exclusive updates.
        </p>
        <FooterSubscribeForm />
      </div>
    </div>
  );
}

export default FooterSubscribeColumn;