import { gsap } from "gsap";
import { useEffect } from "react";

export function useDataWIdAnimation4() {
  useEffect(() => {
    gsap.set('[data-w-id="3f1e8c66-dc14-e31c-04fc-9dd7679fff78"]', {"y": "200px"});
    gsap.set('[data-w-id="3f1e8c66-dc14-e31c-04fc-9dd7679fff78"]', {"opacity": 0});
    gsap.set('[data-w-id="8d407bae-c4be-9939-f9be-ab9b937c7a99"]', {"opacity": 0});
    gsap.set('[data-w-id="552d75a4-d9cb-d22c-1eb6-2aae6bdb1ad4"]', {"opacity": 0});
    const tl = gsap.timeline({});tl.addLabel("label0");
    tl.to('[data-w-id="3f1e8c66-dc14-e31c-04fc-9dd7679fff78"]', {"duration": 0.6, "delay": 0.0, "ease": "power2.out", "y": "0px"}, "label0");
    tl.to('[data-w-id="3f1e8c66-dc14-e31c-04fc-9dd7679fff78"]', {"duration": 0.5, "delay": 0.0, "ease": "power2.inOut", "opacity": 1}, "label0");
    tl.to('[data-w-id="8d407bae-c4be-9939-f9be-ab9b937c7a99"]', {"duration": 0.5, "delay": 0.0, "ease": "none", "opacity": 0}, "label0");
    tl.addLabel("label1");
    tl.to('[data-w-id="8d407bae-c4be-9939-f9be-ab9b937c7a99"]', {"duration": 0.3, "delay": 0.0, "ease": "power2.inOut", "opacity": 1}, "label1");
    tl.to('[data-w-id="552d75a4-d9cb-d22c-1eb6-2aae6bdb1ad4"]', {"duration": 0.5, "delay": 0.0, "ease": "none", "opacity": 0}, "label1");
    tl.addLabel("label2");
    tl.to('[data-w-id="552d75a4-d9cb-d22c-1eb6-2aae6bdb1ad4"]', {"duration": 0.3, "delay": 0.0, "ease": "none", "opacity": 1}, "label2");
  }, []);
}