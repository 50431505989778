import { gsap } from "gsap";
import { useEffect } from "react";

export function useSectionAnimation0() {
  useEffect(() => {
    gsap.set('.section', {"y": "25%"});
    gsap.set('.section', {"opacity": 0});
    const tl = gsap.timeline({"scrollTrigger": {"trigger": ".section", "start": "top bottom", "end": "bottom top", "toggleActions": "play none none none", "once": true}});tl.addLabel("label0");
    tl.to('.section', {"duration": 0.3, "delay": 0.0, "ease": "power2.inOut", "y": "0px"}, "label0");
    tl.to('.section', {"duration": 0.3, "delay": 0.0, "ease": "power2.inOut", "opacity": 1}, "label0");
  }, []);
}