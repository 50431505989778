import React from 'react';

interface SubmitButtonProps {
  disabled?: boolean;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({ disabled }) => {
  return (
    <button
      className="submit-button w-full max-w-[600px] bg-white text-[#CD853F] text-[1.2rem] font-bold leading-[100%] cursor-pointer mt-4 px-6 py-3 rounded-full border-2 border-white transition-colors duration-300 hover:bg-[#CD853F] hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed"
      type="submit"
      disabled={disabled}
    >
      {disabled ? 'Submitting...' : 'Submit'}
    </button>
  );
};

export default SubmitButton;