import { useAnimations } from "../hooks/useAnimations";
import FooterSubscribeColumn from "../components/footer/FooterSubscribeColumn";
import HeroSection from "../components/hero/HeroSection";

function Home() {
  useAnimations();

  return (
    <div
      className="page-wrapper elem57 box-border relative opacity-0 mx-auto"
      data-w-id="3f1e8c66-dc14-e31c-04fc-9dd7679fff78"
    >
      <div className="global-styles w-embed elem58 box-border block fixed left-[0%] right-auto top-[0%] bottom-auto before:content-['_'] before:table after:content-['_'] after:table after:clear-both" />
      <main 
        id="main-content"
        className="main-wrapper home elem2 block box-border overflow-hidden p-[2rem] 
          tablet:pt-[2rem] tablet:pb-[1rem] tablet:px-[2rem] 
          mobile:pb-[0] mobile:pt-[0.25rem] mobile:px-[0.75rem]"
      >
        <HeroSection/>
        
        <footer className="section-footer elem0 flex box-border gap-x-[4rem] gap-y-[4rem] text-white bg-[#CD853F] flex-wrap justify-center items-center mt-[2rem] p-[4rem] w-full rounded-[0.75rem] tablet:flex-col mobile:flex-col tablet:px-[2rem] mobile:px-[1rem] mobile:py-[2rem]">
          <FooterSubscribeColumn/>
          <div className="div-block elem97 box-border w-[100%] text-center">
            <div className="copyright elem98 box-border text-[length:.75rem]">
              © The Bland Company 2024
            </div>
          </div>
        </footer>
      </main>
    </div>
  );
}

export default Home;