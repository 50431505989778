import HeroBackgroundImage from "./HeroBackgroundImage";
import HeroContent from "./HeroContent";
import useResponsiveLayout from "../../hooks/useResponsiveLayout";

function HeroSection() {
  const { isTablet } = useResponsiveLayout();
  return (
    <section 
      className={`section-hero elem7 flex box-border text-center bg-[color:rgba(255,255,255,0)] flex-col justify-between items-start h-auto relative  mb-[2rem] pt-[2rem] pb-[4rem] px-[2rem] rounded-[0.75rem] 
      ${isTablet ? 'tablet:h-auto tablet:pt-[1rem] tablet:pb-[4rem] tablet:px-[1rem] tablet:items-center tablet:justify-center' : ''}
      mobile:pb-[4rem] mobile:px-[1rem] mobile:gap-x-[3rem] mobile:gap-y-[3rem] mobile:text-left mobile:justify-center mobile:items-center mobile:h-auto mobile:mt-[0.5rem] mobile:mb-[0] mobile:pt-[1.5rem] mobile:rounded-[10px]`}
      aria-labelledby="hero-heading"
    >
      <HeroContent />
      <a href="#main-content" className="sr-only focus:not-sr-only">Skip to main content</a>
      <img
        alt=""
        className="certification-stamp elem32 box-border align-middle max-w-[100%] hidden w-[10rem] absolute right-[-1.5rem] border-[0] inset-y-2/4 tablet:top-3/4 tablet:bottom-1/4 mobile:right-[-0.75rem] mobile:top-auto mobile:bottom-[40px]"
        data-w-id="d44eb9ef-c2e6-e97b-d604-a98b79206197"
        loading="lazy"
        src="https://cdn.prod.website-files.com/65665176e9ed6d4b5d95dab2/656653facc5e97cffef946ba_rondal.svg"
      />
      <HeroBackgroundImage aria-hidden="true" />
    </section>
  );
}

export default HeroSection;