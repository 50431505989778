import React, { useState } from 'react';
import { useFormspark } from "@formspark/use-formspark";
import SubmitButton from './SubmitButton';

const FORMSPARK_FORM_ID = "ZxHhN4nHp";

function FooterSubscribeForm() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [submit, submitting] = useFormspark({
    formId: FORMSPARK_FORM_ID,
  });

  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(null);

    try {
      await submit({ name, email });
      setIsSuccess(true);
      setName('');
      setEmail('');
    } catch (err) {
      setError('An error occurred while submitting the form. Please try again.');
    }
  };

  if (isSuccess) {
    return (
      <div className="success-message w-form-done elem92 box-border text-center bg-[color:rgba(255,255,255,0.1)] pl-[20px] pr-[20px] py-[30px] border-[1px] border-white rounded-lg mt-[20px]">
        <div className="text-block elem93 box-border text-center text-[length:1.5rem] font-bold">
          Thank you! We'll notify you when we launch.</div><div className="text-block elem94 box-border text-center text-[length:1rem] mt-[10px]">Stay tuned for exciting updates!
        </div>
      </div>
    );
  }

  return (
    <form
      className="form elem89 box-border h-[auto] mt-[40px] w-full max-w-[600px] mx-auto"
      onSubmit={handleSubmit}
    >
      <div className="form-wrapper elem90 box-border flex-wrap flex flex-col">
        <input
          className="form-input w-input elem37 bg-transparent text-white text-[length:1.2rem] leading-[100%] font-[inherit] box-border align-middle bg-[color:rgba(255,255,255,0)] w-full max-w-[600px] h-[auto] block m-0 mb-4 pl-0 pr-12 py-3 border-b-[0.5px] border-white border-t-0 border-x-0  active:border-y-white placeholder:text-white tablet:rounded-[0] mobile:rounded-[0]"
          id="name-2"
          maxLength={256}
          name="name-2"
          placeholder="Your Name"
          required
          autoComplete="off"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          className="form-input w-input elem38 bg-transparent text-white text-[length:1.2rem] leading-[100%] font-[inherit] box-border align-middle bg-[color:rgba(255,255,255,0)] w-full max-w-[600px] h-[auto] block m-0 mb-4 pl-0 pr-12 py-3 border-b-[0.5px] border-white border-t-0 border-x-0  active:border-y-white placeholder:text-white tablet:rounded-[0] mobile:rounded-[0]"
          id="email-2"
          maxLength={256}
          name="email-2"
          placeholder="Your Email"
          required
          autoComplete="off"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <SubmitButton disabled={submitting} />
        {error && (
          <div className="error-message w-form-fail elem94 box-border bg-[color:#ffdede] mt-[10px] p-[10px]">
            <div className="elem95 box-border">
              {error}
            </div>
          </div>
        )}
      </div>
    </form>
  );
}

export default FooterSubscribeForm;