import React, { useState } from 'react';
import useResponsiveLayout from "../../hooks/useResponsiveLayout";

function HeroContent() {
  const { isTablet, isMobile } = useResponsiveLayout();
  const [isHovered, setIsHovered] = useState(false);

  const scrollToFooter = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const footer = document.querySelector('footer');
    if (footer) {
      footer.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div
      className={`hero-content-wrapper elem62 box-border gap-x-[40px] gap-y-[40px] text-left flex-col self-start items-start w-[46rem] flex opacity-0 
      ${isTablet || isMobile ? 'tablet:w-[40rem] mobile:w-[100%] tablet:items-center mobile:items-center tablet:self-center mobile:self-center tablet:text-center mobile:text-center' : ''}
      mobile:pt-[10vw]`}
      data-w-id="8d407bae-c4be-9939-f9be-ab9b937c7a99"
    >
      <img
        src="https://createsiteai.b-cdn.net/5a68dad8-25dd-4307-ac7d-91161cfdbf55"
        alt="Ingredients for sustainable eating"
        className="w-full mb-8 object-cover"
      />
      <h1 
        id="hero-heading"
        className="heading home elem27 text-[length:4rem] box-border font-normal leading-[1] m-[0] text-gray-700 mobile:text-center tablet:text-center mobile:w-[100%] mobile:text-[length:12vw] mobile:leading-[10.25vw]"
      >
        Ingredients for sustainable eating.
      </h1>
      <div className="w-full flex justify-start tablet:justify-center mobile:justify-center">
        <button
          aria-label="Coming Soon"
          onClick={scrollToFooter}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          className={`custom-button w-button elem23 bg-white box-border cursor-pointer no-underline flex justify-center items-center w-auto text-[1.25rem] mt-[2rem] px-[35px] py-[12px] rounded-[1000px] top-[20px] transition-all duration-300 ${
            isHovered
              ? 'text-[#CD853F] border-[#CD853F]'
              : 'text-black border-[#e0e0e0]'
          } border`}
        >
          Coming Soon
        </button>
      </div>
    </div>
  );
}

export default HeroContent;